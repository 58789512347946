const state = {

    fineChatClient : null,
    apiUrl : null,
    apiToken : null,

    selectedChatUid: null,
    selectedChat : {},
    user : {}
  // Devices

}

const actions = {
}


const mutations = {

    INIT_CLIENT(state) {
        state.apiUrl = process.env.REACT_APP_API_URL
        state.isMediaReady = false
        state.videoOn= false
        state.microphoneOn= false
        state.videoDeviceId = null
        state.audioDeviceId = null
        state.videoSources = []
    }

}


export default {
  namespaced:true,
    state,
    mutations,
    actions,
}
