import FineChatsApiClient from "@modules/chats/plugins/FineChatClient/fineChatsApiClient";
import FineChatsSocketClient from "@modules/chats/plugins/FineChatClient/fineChatsSocketClient";

class FineChats {
    constructor(store) {
        console.log('FineChatsClient constructor');
        this._$store = store
        this.api = new FineChatsApiClient(this)
        this.socket = new FineChatsSocketClient(this)
        this.user = {}
    }

    setUser(user) {
        this.user = user
    }

}



export default FineChats