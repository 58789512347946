import {io} from "socket.io-client";

function generateUID(length = 12) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uid = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        uid += chars[randomIndex];
    }
    return uid;
}

const socketUrl = process.env.VUE_APP_CHATS_SOCKET_URL

class FineChatsSocketClient {

    constructor(fineChats) {
        this._fineChats = fineChats
        this.error = null
        this.io = null
        this.isConnected = false

        this.activeListeners = []
    }

    async getChats(options = {}) {
        return await this.request('chat/get' , options)
    }
    async selectChat(chatUid) {
        return await this.request("chat/select",{chatUid: chatUid})
    }
    async getChatMessages() {
        return await this.request("message/get",{})
    }

    // Technical

    connect(userToken) {
        return new Promise((resolve) => {
            this.io = io(socketUrl, {
                auth: {
                    token: userToken,
                }
            })
            this.io.on('connect', () => {
                this.isConnected = true
                resolve(true)
            })
            this.io.on('disconnected', () => {
                this.isConnected = false
            })
        })

    }

    emit(name,data) {
        this.io.emit(name, data)
    }

    request(name , data ) {
        return new Promise((resolve) => {

            const callbackFunction = (event) => {
                this.io.off(name, callbackFunction)
                if (!event.result) {
                    resolve(false)
                }
                if (event.data) {
                    event = event.data
                }
                resolve(event)
            }
            this.io.on(name, callbackFunction)
            this.io.emit(name, data)
        })
    }

    listen(name , data, callback ) {
        this.io.on(name, callback)
        this.io.emit(name, data)
        let event = {
            uid : generateUID(),
            event : name,
            callback : callback,
        }
        this.activeListeners.push(event)

        return () => this.stopListen(event)

    }

    stopListen(uid) {
        alert('I will turn off listener ID : ' + uid)
        let event = this.activeListeners.find(el => el.uid === uid)
        alert('Listener event : ' + JSON.stringify(event))
        this.io.off(event.name, event.callback)
        this.activeListeners = this.activeListeners.filter(el => el.uid !== uid)
    }





}
export default FineChatsSocketClient
