const apiToken = process.env.VUE_APP_CHATS_API_TOKEN
const appUrl = process.env.VUE_APP_CHATS_API_URL

class FineChatsApiClient {

    /**
     *
     * @param  fineChats : FineChats
     */
    constructor(fineChats) {
        this._fineChats  = fineChats
        this.error = null
    }

    // User

    async authUser(data) {
        let result = await this.request({
            method: 'POST',
            route : 'user/auth',
            body : data
        })
        this._fineChats._$store.state.fineChats.user =result.user

        if ( result && result.auth) {
            this._fineChats.user = result.user
            return await this._fineChats.socket.connect(result.auth)
        }
        return false

    }


    // Technical

    request(options) {
        this.error = null

        console.log('[FineChatsClient:API CALL]' , options);
        const { route , method , body  } = options

        let requestOptions = {
            method: method, // HTTP method
            headers: {
                'Content-Type': 'application/json',
                'X-Chats' : apiToken
            }
        }

        if (body) {
            requestOptions.body = JSON.stringify(body)
        }

        return new Promise((resolve) => {
            fetch(`${appUrl}/${route}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result) {
                        console.log( 'Chats API Call' , route , data.data)
                        resolve(data.data)
                    }
                })
                .catch(error => {
                    console.log( 'Chats API Call Error' , route , error)
                    this.error = error
                    resolve(false)
                })
        })



    }

}
export default FineChatsApiClient
