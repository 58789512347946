const state = {

    webinar : {},
    participants : [],
    onlineParticipantsCount : 0,

    displayVideoInputSidebar : false,


    microphone: [],
    camera: [],
    videoPermission: false,
    audioPermission: false,
    videoOn: false,
    microphoneOn: false,
    allPermissions: null,
    displayStudentStreamRequestDialog : false,
    studentApprovedToStream : false,
    studentWaitingApproveToStream : false

}
const actions = {

    // Webinars
    async GET_WEBINARS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_ONE_WEBINAR({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/${body.uuid}${body.student ? '/student' : ''}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_ONE_WEBINAR_PUBLIC({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/${body.uuid}/public`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_WEBINAR({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async EDIT_WEBINAR({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/${body.uuid}`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async COPY_WEBINAR({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/copy/${body.uuid}`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async DELETE_WEBINARS({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/${body.uuid}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // Webinar Categories
    async GET_WEBINARS_CATEGORIES({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/categories`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_WEBINARS_CATEGORY({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/category`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async EDIT_WEBINARS_CATEGORY({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/category/${body.value}`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async DELETE_WEBINARS_CATEGORY({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/category/${body.value}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_WEBINARS_TO_CATEGORY({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/category/pin/${body.uuid}`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async DELETE_CATEGORY_FROM_WEBINAR({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/category/pin/${body.uuid}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // Webinar Settings

    // Managers
    async GET_ALL_MANAGERS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/managers/select`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_ALL_MANAGERS_FOR_WEBINAR({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/managers`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // Participants
    async GET_ALL_USERS({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/users/unlisted/${body.uuid}?limit=${body.limit}&offset=0`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_PARTICIPANT({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/participants`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async EDIT_PARTICIPANT_PAYMENT_STATUS({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/participants/${body.uuid}`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    async GET_PARTICIPANTS({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/participants/${body.uuid}?limit=${body.limit || 0}&offset=0`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async DELETE_PARTICIPANT({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/participants/remove/${body.uuid}`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },

    // Student
    async GET_STUDENT_WEBINARS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/user/participated/1`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_OTHER_WEBINARS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinars/user/participated/0`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // Chats

    async GET_WEBINAR_CHAT_TOKEN({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/chat/auth`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },




}

export default {
  namespaced:true,
  state,
  actions,
}
